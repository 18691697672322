import { Box, Button, Divider, Grid, Stack, Typography, Modal } from "@mui/material";
import DetailDataSkeleton from "../../../components/template/skeleton/DetailDataSkeleton";
import { InputForm, SelectFormm } from "../../../components/molecules/forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import adminEndpoint from "../../../utils/validation/adminEndpoint";
import AdminPageAccTable from "./AdminPageAccTable";
import { pageAData } from "../../../utils/datajson/dataTableAdmPage";
import { useEffect, useState } from "react";
import { generateRandomNumericId } from "../../../utils/hash/randomNumber";
import SelectForm from "../../../components/molecules/forms/selectFormm";
import { useSearchParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { createEndpointException, deleteEndpointException } from "../../../store/feature/adminEndpointException";

const style = {
  position: "absolute" as "absolute",
  top: "20%",
  left: "20%",
  transform: "translate(-50</Modal>%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  overflowX: "auto",
  maxHeight: '85vh',
};

const AdminPageAcc = ({open, setOpen}: any) => {
  const { dataEndpointException, endpointUID } =
  useAppSelector((root: RootState) => root.admEndpointExp);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const idParam = params.get("adm-page-acces-uid");

  const [dataPage, setDataPage] = useState<any>([]);
  const handleClose = () => {
    setOpen(false);
  };
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    endpointPageName: string;
    endpointMethod: string;
    id: string;
    endpointPath: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminEndpoint),
    defaultValues: {
      endpointPageName: "",
      endpointMethod: "",
      id: "",
      endpointPath: "",
    },
  });

  const optSelect = [
    {
      label: "GET",
      value: "GET",
    },
    {
      label: "POST",
      value: "POST",
    },
    {
      label: "PUT",
      value: "PUT",
    },
    {
      label: "DELETE",
      value: "DELETE",
    },
  ];

  const onSubmit = async (e: any) => {
    setOpen(false)
    let arr = [endpointUID]
    console.log('arr === >', arr)
    await dispatch(deleteEndpointException({
      "uc_adm_pge_access_uid": arr
    }))
    // console.log('e ====> ', e);
    // console.log('dataPage   => ', dataPage)
    let dataPageTable = dataPage.map((item: any) => ({
      uc_adm_endpoint_exception_type: item.uc_adm_endpoint_exception_type,
      uc_adm_endpoint_exception_endpoint:
        item.uc_adm_endpoint_exception_endpoint,
      uc_adm_pge_access_uid: endpointUID,
    }));
    dispatch(createEndpointException(dataPageTable));
  };

  const handleReset = () => {
    setValue("endpointPageName", "");
    setValue("endpointPath", "");
  };
  const handleSubmited = () => {

      setDataPage((prevData: any) => {
        const newDataPage = [...prevData]; // Create a copy of the original data
        console.log('newDataPage ===> ', newDataPage)
      
        // Find the index of the item with the specified ID
        const indexToUpdate = newDataPage.findIndex((item) => item.uc_adm_endpoint_exception_uid === watch("id"));
        console.log('true false ===> ', newDataPage.findIndex((item) => console.log(item)))
      
        if (watch("id") && indexToUpdate !== -1) {
          // If watch("id") has a value and an item with the same ID is found, update the existing item
          newDataPage[indexToUpdate] = {
            ...newDataPage[indexToUpdate],
            uc_adm_endpoint_exception_type: watch("endpointPageName"),
            uc_adm_endpoint_exception_endpoint: watch("endpointPath"),
          };
        } else {
          // If watch("id") is empty or no item with the same ID is found, add a new item
          newDataPage.push({
            uc_adm_endpoint_exception_type: watch("endpointPageName"),
            uc_adm_endpoint_exception_endpoint: watch("endpointPath"),
            uc_adm_endpoint_exception_uid: generateRandomNumericId(6),
          });
        }
      
        return newDataPage;
      });
    setValue("endpointPageName", "");
    setValue("endpointPath", "");
    setValue("id", "")
  };
  useEffect(()=> {
    console.log('dataEndpointException ===> ', dataEndpointException)
    setDataPage(dataEndpointException)
  }, [dataEndpointException])

  return (
      <Modal
        // hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
      <Box sx={{ ...style, width: "50%", padding: 5 }}
          onClick={(event) => {
            // Tutup modal ketika area di luar modal diklik
            if (event.target === event.currentTarget) {
              console.log('hahaha ===> ')
              handleClose();
            }
          }}>
        {/* <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          Endpoint Page
        </Typography> */}
        <Grid container spacing={5} sx={{ marginTop: "30px" }}>
        <Grid xs={1}></Grid>
          <Grid xs={3}>
            <SelectFormm
              name="endpointPageName"
              label="Method"
              options={optSelect}
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
              defaultValue={""}
              isClear
            />
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={3}>
            <InputForm
              name="endpointPath"
              label="Endpoint Path"
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              maxLength={100}
              errors={errors}
              required
            />
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={2} mt="35px">
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button variant="contained" color="gray" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="contained" onClick={handleSubmited}>
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          display={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <AdminPageAccTable
            data={dataPage}
            dataPage={dataPage}
            setDataPage={setDataPage}
            setValue={setValue}
          />
        </Grid>
        <Grid xs={12} mt="35px">
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button variant="contained" color="gray" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={onSubmit}>
                Submit
              </Button>
            </Stack>
          </Grid>
      </Box>
      </Modal>
    // </>
  );
};

export default AdminPageAcc;
